.adminItem {
  background-color: var(--itemColor);
  border-radius: var(--globalBorderRadius);
  border: var(--globalButtonBorder);
  box-shadow: var(--globalBoxShadow);
  margin-bottom: 16px;
  width: 100%;
  min-width: 600px;
  min-height: 100px;

  display: flex;
  flex-direction: row;
  color: var(--globalWhite);
  padding: 16px;
  align-items: center;
  transition: all 0.25s ease-out;
}

.adminItem:hover {
  box-shadow: var(--globalBoxShadow_Hover);
  border-color: rgba(128, 128, 128, 0.48);
  background-color: var(--itemHighColor);
  transition: all 0.05s ease-out;
}

.adminItem > img {
  border-radius: var(--globalBorderRadius);
  aspect-ratio: 1;
  max-height: 65px;
  min-height: 65px;
  text-align: center;
  border: 4px rgba(255, 255, 255, 0.3) solid;
  object-fit: cover;
}

.dataPlate {
  padding: 0 16px;
  width: 30%;
  border-right: 2px rgba(128, 128, 128, 0.15) solid;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: flex-start;
}

.text {
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 5px;
}
.createdDate {
  font-weight: 300;
  font-size: 80%;
}

.tagline {
  padding: 0 16px;
  flex: 1;
  border-right: 2px rgba(128, 128, 128, 0.15) solid;
  max-height: 48px;
  align-self: center;

  text-align: justify;
  word-wrap: break-word;
  overflow-y: auto;
  overflow-x: hidden;
}

/*Customize description scroll bar*/
.tagline::-webkit-scrollbar {
  width: 0.35em;
}

.tagline::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.tagline::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
/*..........................*/

.btnPanel {
  margin-left: 16px;
  min-width: 96px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
}

.btnPanel > i {
  transition: all 0.1s ease-out;
}

.btnPanel > i:hover {
  cursor: var(--globalMouseHand);
  transform: scale(1.02, 1.02);
}

.btnPanel > i:active {
  transform: scale(0.97, 0.97);
}
