@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  position: relative;
  margin: 0;
}

/* h1,
h2,
h3,
h4,
h5 {
  color: var(--primaryHeading-Dark);
} */

h2 {
  margin: 1.5rem 0;
  font-weight: 700;
  font-size: 3rem;
  font-family: 'Poppins', sans-serif;
}

/* h6 {
  color: var(--secondaryText-Green);
} */

hr {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

p {
  font-family: sans-serif;
  font-weight: 400;
  color: var(--primaryText-color);
}

:root {
  /*filter: brightness(250%);*/
  font-size: 14px;
  color: azure;
  /*background-color: #626161;*/
  background-color: var(--globalBgColor);
  position: relative;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100vw;
  /* Global Variables */
  /*Color*/
  --globalGreen: rgb(5, 130, 131);
  --globalGreen_Trans: rgba(5, 133, 133, 0.15);
  --globalRed: #830527;
  --globalWhite: #b0b0b0;
  --globalCream: #dfccbf;
  --globalBgColor_3: #a3a3a3;
  --globalBgColor_2: #1c1c1c;
  --globalBgColor: #333333;

  /* New */
  --primaryText-color: #21333e;
  --primaryText-White: azure;
  --primaryText-Dark: #21333e;
  --secondary-bg-color-dark: #111117;
  --primaryHeading-Dark: #21333e;
  --secondaryText-Green: #00a6a6;
  --secondaryText-Green-Trans: rgba(0, 166, 166, 0.2);

  --primary-bg-color: #f9f9f9;
  --hero-bg-color: #080d10;
  --section-1-bg-color: #ffffff;
  --section-2-bg-color: #111117;
  --section-3-bg-color: #eef4ed;
  --section-5-bg-color: #ffffff;
  --section-6-bg-color: #111117;

  /*Misc*/
  --globalBoxShadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
  rgba(0, 0, 0, 0.23) 0px 3px 6px;
  --globalBoxShadow_Hover: rgba(0, 0, 0, 0.19) 0px 10px 20px,
  rgba(0, 0, 0, 0.23) 0px 6px 6px;
  --globalBoxShadowLight: 0 1px 2px 0px rgba(178, 183, 183, 0.45);
  --globalBoxShadowDark: 0px 2px 4px -1px rgba(0 0 0 0.2),
  0px 4px 5px 0px rgba(0 0 0 0.14), 0px 1px 10px 0px rgba(0 0 0 0.12);
  --globalBoxShadowLight_Clicked: inset 0 -2px 6px 2px rgba(0, 0, 0, 0.39);
  --globalBoxShadowLight_Hover: 0 2px 6px 2px rgba(178, 183, 183, 0.2);
  --globalBorderRadius: 6px;
  --globalMouseHand: pointer;
  --globalButtonBorder: 1px solid rgba(163, 163, 163, 0.3);
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--globalBgColor);
}

/*Scroll bar Customization*/
body::-webkit-scrollbar {
  width: 0.65em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(87, 87, 87, 0.733);
  /*outline: 1px solid slategrey;*/
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(169, 169, 169, 0.64);
  outline: none;
}

/*..............................*/

a {
  color: inherit !important;
  margin: 0;
}

.App {
  position: relative;
  color: white;
  background-color: #f9f9f9;
  /* background: url(./img/newBg.jpg) no-repeat fixed; */
  background-size: cover;
}

/* .App::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.7;
} */

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


/*region Templates*/
.ab-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hw-100 {
  height: 100%;
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.font-poppin-3-center-dark {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  color: #1c1c1c;
  text-align: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}


@media screen and (max-width: 768px) {
  .fontSize-onMobile {
    &.rem-2_2 {
      font-size: 2.2rem !important;
    }
    &.rem-2_5 {
      font-size: 2.5rem !important;
    }
  }

  .containerMargin-onMobile{
    margin: 5% !important;
  }

  .containerPadding-onMobile{
    padding: 5% !important;
  }

  .unsetMin{
    min-height: unset!important;
    min-width: unset!important;
  }
}

/*endregion*/

