.personCard {
  display: flex;
  font-family: Montserrat, sans-serif;
  color: #f9f9f9;
  margin: 0 50px 75px 0;

  h3,p{
    text-align: left;
  }

  p {
    font-size: 14px;
  }

  h3{
    font-weight: 700;
  }

  .avatar {
    aspect-ratio: 3/3.5;
    width: 120px;
    object-fit: cover;
    object-position: top;
    border-radius: 3px;
  }

  ul{
    font-size: 1.2rem;
    flex-direction: row;
    justify-content: flex-start;

    li{
      padding-right: 10px;
    }

    .navLink {
      display: grid;
      place-items: center;
      color: var(--globalGreen)!important;
      height: 42px;
      aspect-ratio: 1/1;
      background-color: rgba(5, 130, 131, 0.15);
      border-radius: 100px;

      &:hover{
        cursor: pointer;
        color: #dedcdc !important;
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .personCard{
    margin: 20px 0;
    width: 50%;

    .avatar{
      margin-bottom: 10px;
    }
    p,h3{
      text-align: center;
      font-size: 85%;
    }

    ul{
      font-size: 1rem;
      justify-content: center;

      >li{
        padding-right: 2%!important;
      }
    }
  }
}