.view {
  width: 100%;
  min-height: 140vh;
  display: grid;
  //grid-template: 50px auto 450px 6fr/150px 150px 1fr 150px 150px;
  grid-template: 0 450px 60px 350px minmax(450px, auto) / repeat(12, 1fr);
  //backdrop-filter: blur(100px);

  color: var(--globalCream);

  .adminLiveEditLink {
    grid-area: 3/-1/4/-2;
    align-self: flex-start;
  }

  > a {
    grid-area: 2/5/3/6;
    margin: auto;
  }


  //Title
  & > h2 {
    grid-area: 2/1/3/-1;

    z-index: 1200;
    color: #ffffff;
    line-height: 3;
    font-family: 'roboto', sans-serif;
    font-size: 3rem;
    align-self: center;
    text-align: center;
    backdrop-filter: brightness(0.5);
  }

  //Gallery
  & > .gallery {
    z-index: 600;
    grid-area: 2/1/3/-1;

    overflow: hidden;
    position: relative;

    //border: solid 5px var(--globalGreen_Trans);
    //padding: 10px;

    & > .overlay {
      z-index: 1000;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: black;
      opacity: 0.7;
    }

    & > div {
      border: none;
      height: 100%;
      width: 100%;
    }

    i {
      display: none;
      font-size: 2rem;
      stroke: var(--primaryText-color);
      stroke-width: 4px;
    }
  }

  //Inline-Gallery
  & > .inlineGallery {
    z-index: 600;
    aspect-ratio: 64/35;
    max-height: 350px;
    grid-area: 4/2/5/-2;
    overflow: hidden;
    //box-shadow: var(--globalBoxShadow);

    //border: solid 5px var(--globalGreen_Trans);
    //padding: 10px;

    & > div {
      border: none;
      height: 100%;
      width: 100%;
    }

    i {
      font-size: 2rem;
      stroke: var(--primaryText-color);
      stroke-width: 4px;
    }
  }

  // Content
  & > .content {
    grid-area: 4/7/6/-2;
    padding: 0 20px;

    // Tagline
    & > h4 {
      font-weight: 600;
      text-align: left;
      color: black;
    }

    & > hr {
      //height: 2px;
      color: #2d373c;
    }

    // Paragraph --> col(2,7)
    & > .text {
      text-align: justify;
      line-height: 1.5rem;
      margin-top: 20px;

      font-size: 1.2rem;
      font-weight: 200;
    }
  }

  > .relates {
    grid-area: -1/1/-2/-1;
    //background-color: var(--secondary-bg-color-dark);
    box-shadow: var(--globalBoxShadow);

    display: flex;
    flex-direction: column;
    margin-top: 100px;
    padding: 30px 50px;

    > h3 {
      margin: 0;
      color: var(--primaryText-color);
      padding: 10px;
      font-family: roboto, sans-serif;

      > span {
        background-color: var(--secondaryText-Green-Trans);
        border-radius: 4px;
        padding: 0 10px;
      }
    }

    > .relatesSpace {
      padding: 10px;
      display: flex;
      flex: 1;

      flex-direction: row;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: flex-start;

      /*Scroll bar Customization*/
      &::-webkit-scrollbar {
        height: 0.65em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(87, 87, 87, 0.733);
        /*outline: 1px solid slategrey;*/
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(169, 169, 169, 0.64);
        outline: none;
      }

      /*..............................*/
    }
  }
}

@media screen and (max-width: 768px) {
  .view {
    display: grid;
    grid-template: 0 auto 50px auto auto auto / repeat(12, 1fr);

    .adminLiveEditLink {
      display: none;
    }

    h2{
      font-size: 2rem;
      letter-spacing: 0.1rem;
    }

    > .gallery {
      aspect-ratio: 64/35;
    }

    .content {
      margin-top: 50px;
      grid-area: 5/1/-2/-1;
    }

    .relatesSpace {
      flex-direction: column !important;

    }

    .relates {
      grid-area: 7/1/-1/-1;
      margin-top: 50px;
      padding: 5%;
    }
  }
}
