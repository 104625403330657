/*"https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_cards"   card template*/

//Variacles
$clrText_Hover: azure;

.projCard {
  width: 360px;
  aspect-ratio: 1/1;
  margin: 15px;
  border-radius: 4px;
  background-color: #05828326;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 2px 0,
  rgba(2, 2, 2, 0.4) 0 2px 6px 2px;
  transition: all 0.1s ease-out;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;


  &:hover {
    color: $clrText_Hover;
    box-shadow: var(--globalBoxShadow_Hover);
    cursor: pointer;
    transform: scale(1.02, 1.02);

    //Children hover effects
    .cover {
      filter: blur(2px);
      transition: all 2s ease-out;
      transform: scale(1, 1);
    }

    .overlay {
      background-color: var(--globalGreen);
      opacity: 0.7;
      transition: all 0.5s ease-out;
    }

    .tagline {
      visibility: visible;
    }

    .title {
      color: $clrText_Hover;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  //Children
  .cover {
    transform: scale(1.1, 1.1);
    z-index: 200;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    align-self: center;
    display: flex;
  }

  .overlay {
    z-index: 400;
    background-color: black;
    opacity: 0.05;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .title {
    z-index: 800;
    bottom: 0;
    height: auto;
    min-height: 35px;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.65);

    font-weight: 600;
    font-size: 17px;
    color: var(--globalWhite);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .tagline {
    visibility: hidden;
    z-index: 800;
    position: absolute;
    width: 100%;

    color: white;
    font-weight: 400;
    word-wrap: break-word;
    padding: 48px;
    place-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .wrapper{
    width: 100% !important;
    border-radius: 6px;

  }
  .projCard {
    margin: 0 !important;
    aspect-ratio: 4/3;
    width: 100%;
  }
}
