a {
  text-decoration: none;
}

//For all Buttons in form

.main {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1200;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  place-items: center;
  padding: 30px 40px;

  /*Scroll bar Customization*/
  &::-webkit-scrollbar {
    width: 0.65em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(87, 87, 87, 0.733);
    /*outline: 1px solid slategrey;*/
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(169, 169, 169, 0.64);
    outline: none;
  }

  /*..............................*/

  & button {
    padding: 0.25em 1em;
    border: solid 1px #6a6a6a;
    background-color: #333333;

    &:hover {
      background-color: #6a6a6a;
    }

    &:active {
      background-color: #333333;
      transition: none;
      transform: scale(0.98, 0.98);
    }
  }

  .form {
    min-height: 610px !important;
    height: 100%;
    aspect-ratio: 6/7;
    border-radius: 4px;
    background-color: black;
    box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.68);
    overflow: hidden;

    .bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background: url(https://source.unsplash.com/TV2gg2kZD1o/600x700) no-repeat
        center;
      opacity: 0.3;
      filter: blur(2px);
    }

    .bg2 {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #2e2e2e;
      //background-image: linear-gradient(180deg, rgba(36, 41, 3, 0.644), rgba(113, 159, 113, 0.29), rgba(36, 41, 3, 0.664));;
      opacity: 0.9;
    }

    .contentWrapper {
      height: 100%;

      display: flex;
      padding: 30px 50px;
      flex-direction: column;
      align-items: self-start;
      justify-content: space-around;

      & > h4 {
        align-self: center;
      }

      .selectorPlate {
        width: 100%;

        padding: 0;
        display: flex;
        justify-content: center;

        & > button {
          border-radius: 1px;
          width: 167px;
        }

        & > button:active {
          transform: scale(1+0.04, 1+0.04);
        }

        .clickedBtn {
          transform: scale(1+0.08, 1+0.08);
          background-color: #6a6a6a;
          color: azure;
        }
      }
    }

    & input,
    & textarea,
    & .imageStock {
      color: var(--globalWhite);
      border: solid 1px #6a6a6a;
      outline: none;
      background-color: #333333;
      min-height: 30px;
      width: 100%;

      box-sizing: border-box;
      padding: 5px;
      resize: none;
    }

    .fileHandlerWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      .imageStock {
        height: 80px;
        padding: 10px;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;

        & > * {
          height: 100%;
          margin-right: 5px;
        }

        & > .clear {
          position: absolute;
          right: 10px;
          top: 10px;
          margin: 0;
          transition: all 0.3s ease-out;

          &:hover {
            color: azure;
            transform: scale(1.03, 1.03);
            cursor: pointer;
          }
        }
      }

      /*Customize description scroll bar*/
      .imageStock::-webkit-scrollbar {
        height: 0.5em;
      }

      .imageStock::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      .imageStock::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }

      /*..........................*/

      .fileHandler {
        width: auto;
        align-self: flex-end;

        *{
          color: var(--globalWhite);
        }

        & > div:first-of-type {
        }

        .visually-hidden {
          position: absolute !important;
          height: 1px;
          width: 1px;
          overflow: hidden;
          clip: rect(1px, 1px, 1px, 1px);
        }

        & > label:last-of-type {
          border: solid 1px rgba(106, 106, 106, 0.5);
          margin: 5px 5px;
          border-radius: 3px;
          padding: 0 5px;

          &:hover {
            cursor: pointer;
            background-color: rgba(106, 106, 106, 0.5);
          }
        }
      }
    }

    & textarea {
      height: 220px;
    }

    .actionBtnPlate {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      & > button {
        margin-right: 0;
        margin-left: 20px;
      }
    }
  }
}
