.footer {
  overflow: hidden;
  height: 350px;
  display: grid;
  grid-template: 1fr 45px / 2fr 2fr 2fr 4fr;
  grid-template-areas:
    'contactIconCol addressCol addressCol2 form'
    '. . . socialIcons';

  * {
    color: var(--globalCream);
  }

  .footerBgImage {
    grid-area: 1/1/-1/-1;
    position: absolute;
    width: 100%;
    transform: translateY(-53%);
    filter: blur(6px);
  }

  .footerBgOverlay {
    grid-area: 1/1/-1/-1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    opacity: 0.8;
  }
}

//& > hr {
//  width: 1px;
//  height: auto;
//}

// Contact icons
.contactIconCol {
  grid-area: contactIconCol;
  width: 300px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 75px 50px 25px 100px;
  text-align: center;

  & i {
    font-size: 3rem;
  }

  & i:hover:first-of-type {
    color: azure;
    cursor: pointer;
    transform: scale(1.04, 1.04);
  }

  & p {
    margin: 0;
    line-height: 100%;

    &:first-line {
      font-weight: bold;
      line-height: 200%

    }
  }
}

// Address
.addressCol {
  font-size: 1.2rem;
  grid-area: addressCol;
  width: 300px;

  padding: 75px 0 25px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.addressCol2 {
  font-size: 1.2rem;
  grid-area: addressCol2;
  width: 300px;

  padding: 75px 0 25px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.addressCol > div:first-of-type, .addressCol2 > div:first-of-type {
  &:first-line {
    font-weight: bold;
    line-height: 200%

  }

}

// Form
.form {
  grid-area: form;

  overflow: hidden;
  padding: 50px 100px 0 100px;
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(2, 1fr);
  justify-items: center;
  align-items: center;

  & > *:nth-child(1) {
    grid-area: 1/1/2/3;
    font-weight: 800;
    margin: 0;
  }

  & > *:nth-child(2) {
    grid-area: 2/1/3/4;
    margin: 0;
    align-self: flex-start;
  }

  & input,
  textarea {
    color: var(--globalWhite);
    border: solid 1px #6a6a6a;
    outline: none;
    background-color: #333333;
    min-height: 30px;
    width: 100%;

    box-sizing: border-box;
    padding: 5px;
    resize: none;
  }

  & textarea::placeholder,
  input::placeholder {
    opacity: 0.5;
  }

  & div {
    grid-area: 3/1/4/3;
    width: 100%;

    display: flex;
    justify-content: center;

    & > input {
      border-radius: 1px;
      outline: none;
    }
  }

  textarea {
    height: 100%;
    grid-column: span 2;
    grid-row: span 2;
  }

  & button {
    grid-column: span 2;
    padding: 0.25em 1em;
    border: solid 1px #6a6a6a;
    background-color: #333333;
    border-radius: 1px;
    width: 200px;

    &:hover {
      background-color: #6a6a6a;
    }

    &:active {
      background-color: #333333;
      transition: none;
      transform: scale(0.98, 0.98);
    }
  }
}

//& > hr {
//  margin: 0;
//}
//
.socialIcons {
  justify-self: stretch;
  grid-area: socialIcons;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 2.5px 100px;

  & > a {
    margin-left: 20px;
    font-size: 2.5rem;
  }

  & > a:hover {
    cursor: pointer;
    transform: scale(1.03, 1.03);
  }

  > a {
    i:hover {
      color: azure;
    }
  }
}

@media screen and(max-width: 768px) {
  .footer {
    height: auto;
    justify-content: center;
    grid-template: repeat(4, auto) 90px/ 1fr;
    grid-template-areas:
    'contactIconCol'
    'addressCol'
    'addressCol2'
    'form'
    'socialIcons';
    font-size: 0.8rem !important;

    i {
      font-size: 2rem;
    }

    .contactIconCol {
      padding: 5% !important;

      > i {
        margin-left: 40px;
      }
    }

    .contactIconCol, .addressCol1 {
      justify-content: space-between !important;
      padding: 5% !important;
      padding-top: 10%!important;

    }

    div, p {
      font-size: 0.8rem !important;
    }

    .addressCol {
      padding: 5% !important;
    }

    .addressCol2 {
      justify-content: center !important;
      padding: 5% !important;
    }

    h1 {
      font-size: 2.5rem !important;
    }

    .footerBgImage {
      height: 100%;
      width: unset;
      object-fit: cover;
      transform: unset;
    }

    > div:not(.footerBgOverlay) {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 50px 50px 0 50px;
      width: 100%;
      text-align: center;
      height: auto;
    }

  }

  .form {
    padding: 5% !important;

    button {
      width: 80%;
      height: 50px;
    }
  }

  .socialIcons {
    padding: 5% !important;
  }

}

